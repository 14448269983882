.prose {
	margin: 0 1.25em;
	column-width: 20em;
	column-gap: 1.25em;
}

.prose>*:first-child {
	/*
		Otherwise column layout looks weird
		as one column will have a gap, but
		the other won't
	*/
	margin-top: 0;
}


